"use client";
import { useState } from "react";
import Image from "next/image";
import IconCloseWhite from "@/components/Icon/IconCloseWhite.svg";
import IconClose from "@/components/Icon/IconClose.svg";
import { prismicSerializer } from "@/lib/helpers";
import { PrismicRichText, PrismicText } from "@prismicio/react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export default function BuyersGuide({ heading, content }) {
  const [showBuyersGuideDialog, setShowBuyersGuideDialog] = useState(false);

  const onCloseDialog = () => {
    document.body.style.overflowY = "auto";
    setShowBuyersGuideDialog(false);
  };
  const onOpenDialog = () => {
    document.body.style.overflowY = "hidden";
    setShowBuyersGuideDialog(true);
  };
  return (
    <>
      <label className="font-[500] text-xs">|</label>
      <label className="font-[500] text-xs underline cursor-pointer" onClick={onOpenDialog}>
        Buyers guide
      </label>
      <div className={`dialog__slide ${showBuyersGuideDialog ? "show" : ""}`}>
        <div className="dialog__content bottom">
          <div className="h-screen w-screen bg-white overflow-y-auto pb-[100px] relative">
            <div className="w-full h-[100px] hidden md:flex bg-grey  justify-end items-center">
              <Image
                src={IconCloseWhite.src}
                alt="close"
                width={24}
                height={24}
                className="w-6 md:w-10 h-6 md:h-10  text-white mr-4 md:mr-14 cursor-pointer"
                onClick={onCloseDialog}
              />
            </div>
            <Image
              src={IconClose.src}
              alt="close"
              width={16}
              height={16}
              className="w-4 h-4 block md:hidden  cursor-pointer absolute right-5 top-5"
              onClick={onCloseDialog}
            />
            <div className="container pb-[80px] px-4">
              <p className="font-bold text-[31px] md:text-[37px] leading-[31px] md:leading-[37px] font-rajdhani uppercase mb-2 text-black pt-10">
                <PrismicText field={heading} />
              </p>
              <div className={classNames(styles.buyersGuideContent)}>
                <PrismicRichText field={content} components={prismicSerializer()} />
              </div>
            </div>
          </div>
        </div>
        <div className="dialog__overlay" onClick={onCloseDialog}></div>
      </div>
    </>
  );
}
