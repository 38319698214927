import Link from "next/link";

export const prismicSerializer = asPath => {
  return {
    paragraph: ({ children }) => <p className="paragraph">{children}</p>,
    heading2: ({ children }) => <h2 className="heading2">{children}</h2>,
    heading3: ({ children }) => <h3 className="heading3">{children}</h3>,
    heading4: ({ children }) => <h4>{children}</h4>,
    heading5: ({ children }) => <h5>{children}</h5>,
    heading6: ({ children }) => <h6>{children}</h6>,
    hyperlink: ({ children, node }) => {
      return (
        <Link href={node.data.url ? `${node.data.url}` : `${asPath}${node.data.uid}`} className="custom-link">
          {children}
        </Link>
      );
    },
  };
};
