import Link from "next/link";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { capitalize, convertToSlug } from "@/utils/common";
import Image from "next/image";
import { collectionIcons } from "@/utils/data";

export default function CardItems({ data, department, collection, vehicleName, brand, vehicle_ids, showIcon = true }) {
  const getHref = item => {
    var url = `/${[department, collection, item.slug].filter(item => item).join("/")}`;
    if (vehicleName) {
      url = `/vehicles/${[vehicleName, collection, item.slug].filter(item => item).join("/")}`;
    }
    if (brand) {
      const slug = convertToSlug(brand) + "-" + item.slug;
      if (collection) return `/brands/${[brand, collection, "c", slug].filter(item => item).join("/")}`;
      url = `/brands/${[brand, collection, slug].filter(item => item).join("/")}`;
    }
    if (vehicle_ids) {
      url += "?vehicle_ids=" + vehicle_ids;
    }
    return url;
  };

  const Icon = ({ data }) => {
    let iconUrl = data.icon;
    if (!iconUrl) {
      const collectionIcon = collectionIcons.find(item => item.slug === data.slug);
      if (collectionIcon) {
        iconUrl = collectionIcon.icon;
      }
    }
    return (
      showIcon &&
      iconUrl && (
        <Image
          className={classNames("w-[48px] md:w-[64px] h-[48px] md:h-[64px] mx-auto")}
          src={iconUrl}
          alt=""
          width={48}
          height={48}
        />
      )
    );
  };

  return data.map((item, index) => (
    <Link
      key={`horizontal-scrolling-item-${index}`}
      href={getHref(item)}
      className={classNames("carousel-item flex flex-col item-center ", styles.carouselItem, {
        "justify-center": !item.icon,
      })}
    >
      <Icon data={item} />
      <span className="text-xs md:text-sm text-center mt-2 leading-[14px]">{capitalize(item.name)}</span>
    </Link>
  ));
}
