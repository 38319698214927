"use client";
import { useCallback, useEffect, useState } from "react";
import IconArrowChevronLeftWhite from "@/components/Icon/IconArrowChevronLeftWhite.svg";
import IconArrowChevronRightWhite from "@/components/Icon/IconArrowChevronRightWhite.svg";
import classNames from "classnames";
import styles from "./styles.module.scss";
import CardItems from "../CardItems";
import Image from "next/image";

export default function HorizontalScrollingMenu({
  department,
  collection,
  vehicleName,
  brand,
  vehicle_ids,
  data,
  showIcon,
  slidesToSlide = 3,
  xxl = 10,
  xl = 9,
  lg = 8,
  md = 5,
  sm = 4,
}) {
  const [butotnNextStyle, setButtonNextStyle] = useState("hidden");
  const handlePrevButtonClick = useCallback(() => {
    const width = document.getElementsByClassName("carousel-item")[0]?.clientWidth || 105;
    const carousel = document.getElementById("carousel");
    if (carousel) {
      var left = width * slidesToSlide;
      carousel.scrollBy({ left: -left, behavior: "smooth" });
    }
  }, [slidesToSlide]);

  const handleNextButtonClick = useCallback(() => {
    const width = document.getElementsByClassName("carousel-item")[0]?.clientWidth || 105;
    const carousel = document.getElementById("carousel");
    if (carousel) {
      var left = width * slidesToSlide;
      carousel.scrollBy({ left: left, behavior: "smooth" });
    }
  }, [slidesToSlide]);

  const handleScroll = useCallback(e => {
    const carousel = document.getElementById("carousel");
    const buttonLeft = document.getElementById("buttonLeft");
    const buttonRight = document.getElementById("buttonRight");
    if (carousel && buttonLeft && buttonRight) {
      const { scrollLeft, scrollWidth, clientWidth } = carousel;
      buttonLeft.style.display = scrollLeft > 0 ? "flex" : "none";
      buttonRight.style.display = scrollLeft + clientWidth < scrollWidth ? "flex" : "none";
    }
  }, []);
  useEffect(() => {
    var count = 0;

    if (window.innerWidth < 768) {
      count = sm;
    } else if (window.innerWidth < 992) {
      count = md;
    } else if (window.innerWidth < 1200) {
      count = lg;
    } else if (window.innerWidth < 1500) {
      count = xl;
    } else {
      count = xxl;
    }
    if (count < data.length) {
      setButtonNextStyle("flex");
    }
  }, [data.length, lg, md, sm, xl, xxl]);

  return (
    <div
      id="container"
      className={classNames("container-lg flex justify-center gap-2 md:gap-4 relative", styles.container)}
    >
      <button
        id="buttonLeft"
        onClick={handlePrevButtonClick}
        className={classNames(
          "w-8 h-8 absolute left-2 md:left-[24px] top-1/2 -translate-y-1/2 z-10 hidden bg-grey rounded-full"
        )}
      >
        <Image
          src={IconArrowChevronLeftWhite.src}
          alt=""
          width="16"
          height="16"
          className="w-4 h-4 text-white m-auto"
        />
      </button>
      <div className="w-[14px] md:w-[0px] h-5 bg-grey-75 absolute z-10 -bottom-1 left-0"></div>
      <div
        id="carousel"
        className={classNames(styles.carouselContainer, " py-6 overflow-x-auto justify-start")}
        onScroll={handleScroll}
      >
        <CardItems
          data={data}
          department={department}
          collection={collection}
          vehicleName={vehicleName}
          brand={brand}
          vehicle_ids={vehicle_ids}
          showIcon={showIcon}
        />
      </div>
      <div className="w-[14px] md:w-[0px] h-5 bg-grey-75 absolute z-10 -bottom-1 right-0"></div>
      <button
        id="buttonRight"
        onClick={handleNextButtonClick}
        className={classNames(
          "w-8 h-8 absolute right-2 md:right-[24px] top-1/2 -translate-y-1/2 z-10 bg-grey rounded-full",
          butotnNextStyle
        )}
      >
        <Image
          src={IconArrowChevronRightWhite.src}
          alt=""
          width="16"
          height="16"
          className="w-4 h-4 text-white m-auto"
        />
      </button>
    </div>
  );
}
